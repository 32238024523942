import { Component, OnInit, Directive } from '@angular/core';

// service
import { BlogService } from './blog.service';

// model
import { BlogArticles } from './blog.model';


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  blogArticles: BlogArticles[];

  constructor(private blogService: BlogService) { }

  ngOnInit() {
    this.blogService.getBlogArticles().subscribe(blogArticles => this.blogArticles = blogArticles);
  }

}
