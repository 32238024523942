<div class="container social">
<h1 class="text-center">Social Media For Senior Living Homes</h1>
<h2>
Let Sarsis take care of your social media posts, so you can focus your efforts on caring for your residents.  Sarsis will save you time, while increasing your digital presence to promote your RCFE/company with high quality and engaging content.
</h2>
  <div class="row">
    <div class="col-12 col-md-4 box">
      <div class="box-shadow">
        <div class="corner-ribbon-mask">
          <div class="corner-ribbon top-left silver shadow">Silver</div>
          <div class="text-center price">$199 /mo</div>
          <div class="package-detail">
            <div class="teaser">&nbsp;</div>
            <div class="feature">2 posts per month</div>
            <ul>
              <li>Branded graphic/photo post</li>
              <li>Branded Short Video</li>
            </ul>
          </div>
          <a class="btn view-live center-block" href="#" target="_blank">Subscribe</a>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-4 box">
      <div class="box-shadow">
        <div class="corner-ribbon-mask">
          <div class="corner-ribbon top-left gold shadow">Gold</div>
          <div class="text-center price">$299 /mo</div>
          <div class="package-detail">
            <div class="teaser">Everything in Silver membership, plus</div>
            <div class="feature">4 Custom-made posts per month</div>
            <ul>
              <li>Custom Facebook Page Banner</li>
              <li>Company Logo Video</li>
            </ul>
          </div>
          <a class="btn view-live center-block" href="#" target="_blank">Subscribe</a>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-4 box">
      <div class="box-shadow">
        <div class="corner-ribbon-mask">
          <div class="corner-ribbon top-left platinum shadow">Platinum</div>
          <div class="text-center price">$499 /mo</div>
          <div class="package-detail">
            <div class="teaser">Everything in Gold membership, plus</div>
            <div class="feature">Facebook Page Management</div>
            <ul>
              <li>1 additional post every other month</li>
              <li>Sarsis will take professional photos of your RCFE</li>
              <li>Up to 10 photos</li>
              <li>Sarsis will schedule posts and manage your Facebook page</li>
            </ul>
          </div>
          <a class="btn view-live center-block" href="#" target="_blank">Subscribe</a>
        </div>
      </div>
    </div>

  </div>
</div>
