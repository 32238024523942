<!-- parallax -->
<div class="container-fluid p-0">
    <div class="value-hero-parallax text-center">
        <h1>HOW WE BUILD VALUE</h1>
        <h3>6 STEP WORKFLOW PROCESS</h3>
    </div>
</div>
<div class="container p-5 text-center">
  <img class="img-fluid" src="/assets/images/SARSIS-PROCESS-V1.0.jpg" />
  <br/>
  <a href="/assets/pdf/SARSIS-PROCESS-V1.0.pdf" target="_blank">DOWNLOAD THE PDF</a>
</div>
<div class="container text-center value-steps">
  <div class="row">
      <div class="col-sm-2" onmouseover="hover('First we conduct a series of interviews with the client to listen to their pain point. We often quietly glean this information before even signing a contract. Common issues are: their site is not mobile responsive, it’s not user friendly enough, or they’re having challenges getting it to look the way they want.')">
          <span class="glyphicon glyphicon-user" alt="Understand the Client’s Pain Points" title="Understand the Client’s Pain Points"></span>
          <h3>STEP 1</h3>
          <h3>Understand the Client’s Pain Points</h3>
      </div>
      <div class="col-sm-2" onmouseover="hover('The client works with our designers to sketch out a wireframe on paper. Then the designer builds a mockup in Photoshop. This is a time consuming process, but yields high dividends because the client can oversee and approve the direction of the project before committing to costly development. During the mockup phase, text content, social media icons, and image assets are baked into the design. Doing so ensures a unified, homogenous design with all content considered, as content added later often distorts the original intent of the design. A mockup can have many pages. Even a small site can have 30 pages. Mobile requires its own mockup, as the mobile experience is considerably unique from the desktop. The designer also develops a style guide, specifying specific color codes, button styles, font, and content spacing. In addition, supplemental annotations are provided to clarify design intentions that aren’t immediately apparent from the mockup. This improves the productivity of the coder.')">
          <span class="glyphicon glyphicon-edit" alt="Design in Photoshop" title="Design in Photoshop"></span>
          <h3>STEP 2</h3>
          <h3>Design in Photoshop</h3>
      </div>
      <div class="col-sm-2" onmouseover="hover('The designer feeds the design into InVision. InVision makes the mockup come alive and interactive, allowing the client to click through the site and navigate the workflow.')">
          <span class="glyphicon glyphicon-cloud" alt="Invision App" title="Invision App"></span>
          <h3>STEP 3</h3>
          <h3>InVision App</h3>
      </div>
      <div class="col-sm-2" onmouseover="hover('Once the client reviews all materials and signs off, the design goes into development')">
          <span class="glyphicon glyphicon-ok" alt="Client Review & Approval" title="Client Review & Approval"></span>
          <h3>STEP 4</h3>
          <h3>Client Review & Approval</h3>
      </div>
      <div class="col-sm-2" onmouseover="hover('In pre-development, the coder researches pre-built, off-the-shelf components that may help expedite development, such as menus, image galleries, and UI frameworks. Then the work begins to code the website. Coding is done in pairs. One person does the UI work, while the other does the back end and database programming. The UI developer works closely with the designer to obtain web ready images. The back end developer works with the UI developer to provide the necessary end points to make the site function. With our continuous releases, the client frequently sees updates to the project.')">
          <span class="glyphicon glyphicon-blackboard" alt="Development & Frequent Releases" title="Development & Frequent Releases"></span>
          <h3>STEP 5</h3>
          <h3>Development & Frequent Releases</h3>
      </div>
      <div class="col-sm-2" onmouseover="hover('After several rounds of testing, which includes functional and load testing, we coordinate a time to point the DNS over, and the site is live!')">
          <span class="glyphicon glyphicon-check" alt="Testing & Launch!" title="Testing & Launch!"></span>
          <h3>STEP 6</h3>
          <h3>Testing & Launch!</h3>
      </div>
  </div>
</div>

<!-- process steps content -->
<div class="container-fluid text-center value-content">
  <div class="row">
      <div class="container">
          <div class="col-sm-12">
              <p id="content">First we conduct a series of interviews with the client to listen to their pain point. We often quietly glean this information before even signing a contract. Common issues are: their site is not mobile responsive, it’s not user friendly enough, or they’re having challenges getting it to look the way they want.</p>
          </div>
      </div>
  </div>
</div>
