<!-- hero image parallax and content -->
<!-- parallax -->
<div class="container-fluid p-0">
    <div class="web-hero-parallax">
        <div class="web-caption">
            <h1>WEB APPLICATION DEVELOPMENT</h1>
        </div>
    </div>
</div>

<!-- row 1 -->
<div class="container web-dev">
  <div class="row">
      <div class="col-sm-8">
          <h1>That are True to Design, True to your Intent, And Perfectly Responsive</h1>
          <br />
          <p>
              Our web graphic designer works with you to make sure we have a design that matches your intent. After
              you sign off on the design, our HTML coder painstakingly builds each page to match the design exactly.
              In our studio, we don’t compromise, and we think you’ll be impressed. Please check out our portfolio to
              see how closely the final build can match the photoshop mockups!
          </p>
          <p>
              Each responsive page we build is thoroughly tested to display correctly on infinite screen widths, from a
              small phone to a large 27” retina display.
          </p>
          <br />
      </div>
      <div class="col-sm-4">
          <img alt="Mobile-First Mobile Friendly Responsive Design" title="Mobile-First Mobile Friendly Responsive Design" class="img-fluid" src="assets/images/responsive-design.png" />
      </div>
  </div>
</div>

<!-- row 2 -->
<div class="container-fluid methodology">
  <div class="container">
      <div class="row">
          <div class="col-sm-4 d-none d-sm-block">
              <img alt="Web Development Service" title="Web Development Service" class="img-fluid " src="assets/images/redesign-services-bg.jpg" />
          </div>
          <div class="col-sm-8">
              <div class="col-sm-12 methodology-content">
                  <h1>Our Methodology: Simplicity is our Secret</h1>
                  <br />
                  <p>
                      We start with the most minimally invasive strategy possible, and that is to build an Angular single page
                      application that co-exists with your current website (if you have one). This gives us flexibility to create a
                      brand new responsive design without altering your existing code. This is preferred because there is no
                      risk to your existing website. To launch the new Angular2 site, we simply insert a small javascript snippet
                      into your website. That runs when the page loads, and launches the new website, overwriting your old
                      site. If the migration causes any problems, we simply disable the script while we resolve the issue, and
                      your customers continue to use the old site.
                  </p>
                  <p>
                      The Angular strategy is flexible enough to work with any platform you currently have. We avoid platform
                      migrations when possible because it is risky, time-consuming, and is always more complicated than
                      expected. If you have a popular platform like Magento, we integrate with their API to pull data from the
                      database. If your platform does not have an API, we use a special web crawler to pull data directly from
                      your webpage.
                  </p>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- rest api parallax -->
<div class="container-fluid p-0">

      <div class="rest-parallax">
          <div class="rest-caption">
              <div class="container">
                  <div class="col-md-7 col-sm-8 col-centered text-center">
                      <h1>Full REST API Architecture</h1>
                      <br />
                      <h3>
                          We rigorously encapsulate all data in a proper REST API. This allows your application to be easily expanded in
                          the future to different platforms, like an Android or iOS App, for example.
                      </h3>
                  </div>
              </div>
          </div>
      </div>

</div>
