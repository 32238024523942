import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, TemplateRef } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  imports: [ CommonModule, RouterModule ]
})
export class HeaderComponent implements OnInit {
  private offcanvasService = inject (NgbOffcanvas);
  closeResult = ''; 
  showPromoRibbon: boolean;

  constructor() {
    this.showPromoRibbon = false;
  }

  ngOnInit() {
  }

  closePromoRibbon() {
    this.showPromoRibbon = false;
  }

  openNav(content: TemplateRef<any>) {
		this.offcanvasService.open(content, { ariaLabelledBy: 'offcanvas-basic-title', animation: false }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed with Reason`;
			},
		);
	}

}
