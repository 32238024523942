<div class="hero-image">
  <div class="hero-text">
      <h1>Coronavirus (COVID-19) Update</h1>
      <h3>Back to Work! (Safely)</h3>
  </div>
</div>

<div class="container">
  <div class="row careers">
      <div class="col-xs-12 col-md-8 col-md-offset-2">
          <h2><strong>Coronavirus in San Diego County and Beyond</strong></h2>
          <br />
          <p>Update September 9th, 2020</p>
          <p>
            We've been so busy adapting to new regulations we haven't updated this page in a while.
            <br/>
            In August, we were allowed to re-enter our clients' premises to take photos for marketing, including long-term care facilities.
            <br/>
            <br/>
            We are allowing clients to come in to our office, but on a limited basis, since our office is quite small. A maximum of three guests is allowed.
            Usually, clients would come in for us to do product photography for their website. Everything else is still done virtually.
            <br/>
            <br/>
          </p>
          <br />
          <p>Update May 8th, 2020</p>
          <p>Sarsis remains operational with all staff tele-working. We are awaiting clarification from the County of San Diego to
            determine if Sarsis qualifies as Part of Phase 2 for allowing clients on site.
          </p>
          <br />
          <p>Update March 2020</p>
          <br />
          <p>
            Sarsis remains open and staffed for our clients.
            We've modified our services to meet CDC, state and county health guidelines to "flatten the curve"
            and slow the spread of COVID-19.
            <br/>
            <br/>
            Design and production schedules are still being honored, except for when high priority work is requested by health agencies.
            <br/>
            <br/>
            Sarsis holds a contract with the County of San Diego, and in recent days,
            we are working to pass along critical safety guidelines and orders to long term care facilities serving older adults.
            <br/>
            <br/>
            Our adjustments include:

          </p>
          <ul>
            <li>In person meetings switched to phone or online</li>
            <li>Cancellation of photography sessions, in particular at long term care facilities</li>
            <li>Postponing networking meetings, per the county's "gatherings" rule</li>
            <li>Telecommuting when possible. In-office meetings maintain a 6 feet separation among staff</li>
            <li>Frequently cleaning door knobs and office furniture with disinfectant wipes</li>
          </ul>
          <p>
            More frequent updates may be found on our Instagram page at: <a href="https://www.instagram.com/sarsiswebdevelopers/" target="_blank">&#64;sarsiswebdevelopers</a>
          </p>
      </div>
      <div class="col-12 col-md-4">
        <img class="img-fluid" src="/assets/images/pancho-temperature-check.jpg"/>
        It looks like Pancho the Programmer is ok at 96.5 degrees.
      </div>
  </div>
</div>
