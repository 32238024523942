import { Component, OnInit, Directive} from '@angular/core';
import { QuoteService } from './quote.service'
import { Quote } from './quote';
import { QuoteViewModel } from './quote.view-model';
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css']
})
export class QuoteComponent implements OnInit {

  constructor(private quoteService: QuoteService, meta: Meta, title: Title) {

    title.setTitle('Sarsis | Request a free quote');

            meta.addTags([
              { name: 'author',   content: 'sarsis.com'},
              { name: 'keywords', content: 'Enterprise Web Application Development, Web Development, E-commerce, Angular 2/4 Service, Single Page Application Development, C#, .NET, Javascript, HTML, CSS, Bootstrap 3/4'},
              { name: 'description', content: 'Enterprise Web Application Development Company located in San Diego' }
            ]);

   }

  ngOnInit() {
  }

  // quote form
  quoteViewModel = new QuoteViewModel();

  // quote form
  sendQuote() {
      let quoteModel = new Quote();
      quoteModel.MerchantID = "SArsis1122";
      quoteModel.From = this.quoteViewModel.Email;
      quoteModel.Name = this.quoteViewModel.FullName;
      quoteModel.Phone = this.quoteViewModel.Phone;
      quoteModel.Comments = this.quoteViewModel.Description
          + "<br />" + this.quoteViewModel.Reason
          + "<br />" + this.quoteViewModel.Deadline;
      quoteModel.Subject = "Quote from Sarsis.com";

      this.quoteService.sendQuote(quoteModel).subscribe(data => {
          alert(data);
      });
  }

}