<!-- parallax -->
<div class="container-fluid p-0">
      <div class="about-hero-parallax">
          <div class="container">
              <div class="col-sm-8 col-centered">
                  <h1>ABOUT US</h1>
                  <br />
                  <h3>
                      Our philosophy is to deliver your website in the least amount of work possible. The first benefit is
                      obviously, lower cost. The second benefit is, simpler designs are more likely to succeed. Simpler designs
                      also mean less risk to your business, especially if it’s already generating revenue. In our office, there is
                      no one-size- fits-all solution. We are unique because we analyze each client’s unique situation, and then
                      choose the optimal design methodology and tools that maximizes the odds of a successful website
                      launch.
                  </h3>
              </div>
          </div>
      </div>
</div>
<div class="container the-team">
    <div class="row">
        <!-- employees -->
        <div class="col-md-3 col-sm-6 text-center team-wrapper" (click)="openModal(content, 'quan')">
            <img class="img-fluid" src="/assets/images/about-us/quan.jpg" alt="Quan Nguyen" title="Quan Nguyen"/>
            <div class="team-caption">
                <h4>Quan Nguyen<br />
                Senior Software Engineer</h4>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 text-center team-wrapper" (click)="openModal(content, 'luan')">
            <img class="img-fluid" src="/assets/images/about-us/no-pic2.jpg" alt="Luan Pham" title="Luan Pham" />
            <div class="team-caption">
                <h4>Luan Pham<br />
                Senior Software Engineer</h4>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 text-center team-wrapper" (click)="openModal(content, 'nino')">
            <img class="img-fluid" src="/assets/images/about-us/ninoshka-rios.jpg" alt="Ninoshka Rios" title="Ninoshka Rios" />
            <div class="team-caption">
                <h4>Ninoshka Rios<br />
                Senior UX Designer</h4>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 text-center team-wrapper">
            <img class="img-fluid" src="/assets/images/about-us/hilda.jpg" alt="Hilda Huambachano" title="Hilda Huambachano" />
            <div class="team-caption">
                <h4>Hilda Huambachano<br />
                Chief Health Promotions Officer</h4>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 text-center team-wrapper">
            <img class="img-fluid" src="/assets/images/about-us/bianca.jpg" alt="Bianca Cervantes" title="Bianca Cervantes" />
            <div class="team-caption">
                <h4>Bianca Cervantes<br />
                Program Manager III</h4>
            </div>
        </div>      
        <div class="col-md-3 col-sm-6 text-center team-wrapper">
            <img class="img-fluid" src="/assets/images/about-us/alex.jpg" alt="Alex Alfaro" title="Alex Alfaro" />
            <div class="team-caption">
                <h4>Alex Alfaro<br />
                Program Manager II</h4>
            </div>
        </div>      
    </div>
    <div class="row">
        <div class="col-sm-12 team-bio">
            <div class="col-sm-12">

            </div>
        </div>
    </div>
</div>
<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Profile</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
        <div *ngIf="this.profileName == 'quan'">
            Quan designs and builds the back end services that drives complex web applications. He has over 10 years of experience writing software that is easy to use, maintain, and scalable. Quan is always scanning the industry horizon looking for new technology to better serve clients. When he can’t find it, he builds his own. Quan’s software designs continue to be used successfully today in many organizations in San Diego. Quan holds a BS in Computer Science from UC San Diego. When he is not coding, he enjoys flying aerobatic model aircraft, playing the piano, and cooking.                
        </div>
        <div *ngIf="this.profileName == 'luan'">
            Luan is a senior software engineer and researcher. He delves into deep problems and looks for elegant solutions that are simple and effective. Always pursuing near perfection, Luan takes the time to develop the proper software to solve our clients’ challenges. He holds a Masters in Computer Science, specializing in Machine Learning. Luan currently works remotely in the ski resort town of Grenoble, France, a two hour drive from Paris. His only hobby right now is learning the French language.
        </div>
        <div *ngIf="this.profileName == 'nino'">
            I am Ninoshka, a professional web and graphic designer. I like solving clients’ needs by delivering beautiful designs that appeal to the user. These are some of the companies I’ve designed for: American Red Cross, Measles and Rubella Initiative, Watkins Wellness,  Women for Women International  & more. When I’m not designing I like to draw, find new places to eat and watch youtube videos .
        </div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">Close</button>
	</div>
</ng-template>