import { Component, OnInit, Directive} from '@angular/core';

@Component({
  selector: 'app-rcfe-social-media',
  templateUrl: './rcfe-social-media.component.html',
  styleUrls: ['./rcfe-social-media.component.css']
})
export class RcfeSocialMediaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
