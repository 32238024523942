import { Component, OnInit, Directive} from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";


@Component({
  selector: 'app-web-development',
  templateUrl: './web-development.component.html',
  styleUrls: ['./web-development.component.css']
})
export class WebDevelopmentComponent implements OnInit {

  constructor(meta: Meta, title: Title) {

        title.setTitle('Sarsis | Web Development, Front-end development, Back-end development, Re-design');

            meta.addTags([
              { name: 'author',   content: 'sarsis.com'},
              { name: 'keywords', content: 'HTML5, CSS3, SQL, Javascript, Website Re-design Enterprise Web Development, Web Development, E-commerce, Angular 2/4 Service, Single Page Application Development, C#, .NET'},
              { name: 'description', content: 'Enterprise Web Application Development Company located in San Diego, CA' }
            ]);
       }

  ngOnInit() {
  }

}
