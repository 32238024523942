<div class="container blog">
  <div class="row">
    <div class="col-md-7 blog-post">
        <a class="blog-title" href="#"><h2>How to deploy Angular Universal to Azure</h2></a>
      <br>
      <img class="img-responsive" alt="Angular Universal + Azure" title="Angular Universal + Azure" src="./assets/images/angular-azure.jpg">
      <br>
      <p class="post-by">September 11, 2017&nbsp;&nbsp;&nbsp; Author: Quan Nguyen &nbsp;&nbsp;&nbsp;<i class="fa fa-comment-o" aria-hidden="true"></i> <a href="#">2 Comments</a></p>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      <br>
      <button type="button" routerLink="/angular" class="btn btn-primary btn-lg">Read More</button>
    </div>
    <div class="col-md-1"></div>
    <div class="col-sm-4 latest-post-wrapper clearfix">
        <div class="panel panel-default">
          <div class="panel-heading">Currently on Tap</div>
          <div class="panel-body">
            <div class="row latest-post">
              <div class="col-sm-4 col-xs-4">
                <img class="img-responsive" alt="Angular Universal + Azure" title="Angular Universal + Azure" src="./assets/images/node-expressjs-avatar.jpg">
              </div>
              <div class="col-sm-8 col-xs-8">
                <a href="#">How to deploy Angular Universal to Azure</a>
              </div>
            </div>
           </div>
       </div>
    </div>
  </div>
</div>