<div *ngIf="showPromoRibbon" class="promo-ribbon">

  <div class="container-fluid">
    <div class="row">
      <div class="d-none d-sm-block col-sm-1 col-md-1 col-lg-1">

      </div>
      <div class="col-11 col-sm-10 col-md-10 col-lg-10 text-center vertical-center">
            <span class="promo-text">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;
              <a routerLink="'/coronavirus'" class="promo-text">Sarsis response to COVID-19. Read more.</a>
            </span>
      </div>
      <div class="col-1 vertical-center text-right p-0 pt-2 pr-1 p-sm-3">
        <span class="promo-x-button" (click)="closePromoRibbon()"><i class="fa fa-times fa-3x" aria-hidden="true"></i></span>
      </div>
    </div>
  </div>
</div>
<!-- navigation -->
<div class="container-fluid d-none d-md-block top-bar">
  <div class="row">
      <div class="col-sm-4">
          <a routerLink="home"><img alt="Sarsis Front-end Web Developers" class="img-responsive" title="Sarsis Front-end Web Developers" src="./assets/images/sarsis-nav-logo.png"></a>
      </div>
      <div class="col-sm-8 d-none d-md-block">
          <div class="dropdown">
              <button class="dropbtn">CONTACT</button>
              <div class="dropdown-content">
                  <a routerLink="contact">Contact Us</a>
                  <a routerLink="quote">Get a Quote</a>
              </div>
          </div>

          <div class="dropdown">
              <button class="dropbtn">COMPANY</button>
              <div class="dropdown-content">
                  <a routerLink="about-us">About Us</a>
                  <a routerLink="how-we-build-value">How We Build Value</a>
                  <a routerLink="why-choose-us">Why Choose Us?</a>
                  <a routerLink="careers">Careers</a>
              </div>
          </div>
          <div class="dropdown">
              <button class="dropbtn" routerLink="portfolio">PORTFOLIO</button>
          </div>
          <div class="dropdown">
              <button class="dropbtn">SERVICES</button>
              <div class="dropdown-content">
                  <a routerLink="web-development">Web Development</a>
                  <a routerLink="single-page-application">SPA Applications</a>
                  <a routerLink="angular">Angular</a>
                  <a routerLink="e-commerce">E-commerce Platform</a>
                  <a routerLink="ui-ux">UI/UX</a>
                  <a routerLink="seo">SEO</a>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- end.navigation-->

<!-- mobile navigation -->
<div class="container-fluid top-bar d-md-none">
  <div class="d-flex justify-content-between">
      <div >
          <span style="font-size:30px; cursor:pointer" (click)="openNav(content)"><strong>&#9776;</strong></span>
      </div>
      <div >
          <a routerLink="home"><img alt="Sarsis Front-end Web Developers" class="img-fluid" title="Sarsis Front-end Web Developers" src="./assets/images/sarsis-nav-logo.png"></a>
      </div>
  </div>
</div>

<ng-template #content let-offcanvas class="">
	<div class="offcanvas-header">
		<h4 class="offcanvas-title" id="offcanvas-basic-title"></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
	</div>
	<div class="offcanvas-body sidenav">
    <a routerLink="/portfolio" (click)="offcanvas.dismiss('Cross click')">Portfolio</a>
    <br/>
    <a routerLink="/about-us" (click)="offcanvas.dismiss('Cross click')">Company</a>
    <br/>
    <a routerLink="/how-we-build-value" (click)="offcanvas.dismiss('Cross click')">How We Build Value</a>
    <br/>
    <a routerLink="/contact" (click)="offcanvas.dismiss('Cross click')">Contact</a>
	</div>
</ng-template>
<!-- end.navigation-->
