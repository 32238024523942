import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { AppSettings } from '../appSettings';

import { Quote } from './quote';


@Injectable()
export class QuoteService {
    constructor(private http: HttpClient) { }
    sendQuote(model: Quote): Observable<string> {

        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = { headers: headers };

        return this.http.post<SendLeadEmailResponse>(AppSettings.API_ENDPOINT + "SendEmail/SendLeadEmail",
                                      JSON.stringify(model), options)
            .pipe(
              map(response => response.Response)
            );



        //.catch(error => {
        //    console.log(error);
        //    return Observable.throw(error);
        //});

    }
}

class SendLeadEmailResponse {
  Response: string;
}
