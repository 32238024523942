import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { AngularComponent } from './angular/angular.component';
import { CareersComponent } from './careers/careers.component';
import { CaseStudiesComponent } from './case-studies/case-studies.component';
import { CategoryComponent } from './category/category.component';
import { ContactComponent } from './contact/contact.component';
import { ECommerceComponent } from './e-commerce/e-commerce.component';
import { HowWeBuildValueComponent } from './how-we-build-value/how-we-build-value.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { QuoteComponent } from './quote/quote.component';
import { SeoComponent } from './seo/seo.component';
import { SinglePageApplicationComponent } from './single-page-application/single-page-application.component';
import { UiUxComponent } from './ui-ux/ui-ux.component';
import { WhyChooseUsComponent } from './why-choose-us/why-choose-us.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { BlogComponent } from './blog/blog.component';
import { CoronavirusComponent } from './coronavirus/coronavirus.component';
import { SdlhTechPublicHealthComponent } from './blog/sdlh-tech-public-health/sdlh-tech-public-health.component';
import { Tech2connectComponent } from './tech2connect/tech2connect.component';
import { RcfeSocialMediaComponent } from './rcfe-social-media/rcfe-social-media.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: { title: 'Home'} },
  { path: 'home', component: HomeComponent, data: { title: 'Home'} },
  { path: 'web-development', component: WebDevelopmentComponent, data: { title: 'Web Development'} },
  { path: 'single-page-application', component: SinglePageApplicationComponent, data: { title: 'Single Page Application'} },
  { path: 'angular', component: AngularComponent, data: { title: 'Angular'} },
  { path: 'e-commerce', component: ECommerceComponent, data: { title: 'E-Commerce'} },
  { path: 'ui-ux', component: UiUxComponent, data: { title: 'UI-UX'} },
  { path: 'seo', component: SeoComponent, data: { title: 'SEO'} },
  { path: 'portfolio', component: PortfolioComponent, data: { title: 'Portfolio'} },
  { path: 'case-studies', component: CaseStudiesComponent, data: { title: 'Case Studies'} },
  { path: 'about-us', component: AboutUsComponent, data: { title: 'About Us'} },
  { path: 'how-we-build-value', component: HowWeBuildValueComponent, data: { title: 'How We Build Value'} },
  { path: 'why-choose-us', component: WhyChooseUsComponent, data: { title: 'Why Choose Us'} },
  { path: 'careers', component: CareersComponent, data: { title: 'Careers'} },
  { path: 'contact', component: ContactComponent, data: { title: 'Contact'} },
  { path: 'quote', component: QuoteComponent, data: { title: 'Quote'} },
  { path: 'blog', component: BlogComponent, data: { title: 'Blog'} },
  { path: 'coronavirus', component: CoronavirusComponent, data: { title: 'Coronavirus'} },
  { path: 'assisted-living-social-media', component: RcfeSocialMediaComponent },
  { path: 'blog/sdlh-tech-public-health', component: SdlhTechPublicHealthComponent },
  { path: 'tech2connect', component: Tech2connectComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
