<!-- banner -->
<div class="container-fluid">
  <div class="row">
      <img class="img-fluid" src="/assets/images/why-choose-us-hero.jpg" />
  </div>
</div>


<div class="container why-choose-us text-center">
  <div class="row">
    <div class="col-sm-12 why-title">
        <h2>Why Choose Us to Build your Site</h2><br />
    </div>
    <div class="col-sm-6">
        <i class="fas fa-edit fa-5x"></i>
        <h2>Accurate Estimates</h2>
        <p>We task out projects in detail before committing to code. This gives our clients accurate, predictable delivery timelines with no surprises.</p>
    </div>
    <div class="col-sm-6">
        <i class="fas fa-random fa-5x"></i>
        <h2>No Migration</h2>
        <p>We make every effort to leverage code you already have. If you have a platform or tools you already like, we build around that. Our design strategy is minimally invasive.</p>
    </div>
    <div class="col-sm-6">
        <i class="fas fa-wrench fa-5x"></i>
        <h2>Accurate Implementation</h2>
        <p>We rigorously adhere to the design specs to ensure the website comes out exactly the way you expect. Our HTML will be a carbon copy of the design.</p>
    </div>
    <div class="col-sm-6">
        <i class="fas fa-cogs fa-5x"></i>
        <h2>Right Technology</h2>
        <p>We have depth and breadth of knowledge in Angular, Cloud architecture, and a passion for staying on the leading edge of tech.</p>
    </div>
  </div>
</div>
