import { Component, OnInit, Directive } from '@angular/core';
import { ContactService } from './contact.service'
import { Contact } from './contact';
import { ContactViewModel } from './contact.view-model';
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private contactService: ContactService, meta: Meta, title: Title) {

    title.setTitle('Sarsis | Contact Us Today');

            meta.addTags([
              { name: 'author',   content: 'sarsis.com'},
              { name: 'keywords', content: 'Enterprise Web Application Development, Web Development, E-commerce, Angular 2/4 Service, Single Page Application Development, C#, .NET, Javascript, HTML, CSS, Bootstrap 3/4'},
              { name: 'description', content: 'Enterprise Web Application Development Company located in San Diego' }
            ]);

  }

  contactViewModel = new ContactViewModel();

  // contact form submission
  submitContact() {
    let contactModel = new Contact();
    contactModel.MerchantID = "SArsis1122";
    contactModel.From = this.contactViewModel.Email;
    contactModel.Name = this.contactViewModel.FullName;
    contactModel.Phone = this.contactViewModel.Phone;
    contactModel.Comments = this.contactViewModel.Message;
    contactModel.Subject = "Contact from Sarsis.com";
    this.contactService.sendEmail(contactModel).subscribe(data => {
        alert(data);
    });
}

  ngOnInit() {
  }

}