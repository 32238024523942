import { Component, OnInit, Directive } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";

// declare jquery
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(meta: Meta, title: Title) {

    title.setTitle('Sarsis Health and Technology Solutions');

        meta.addTags([
          { name: 'author',   content: 'Sarsis'},
          { name: 'keywords', content: 'Enterprise Web Application Development, Health, E-commerce, Angular 8, Single Page Application Development, C#, .NET, Javascript, HTML, CSS, Bootstrap 4'},
          { name: 'description', content: 'Health and technology company in San Diego specializing in software engineering, digital design, and print media for health and other industries.' },
          { property: 'og:title', content: 'Sarsis Health and Technology Solutions'},
          { property: 'og:image', content: 'https://www.sarsis.com/assets/images/sarsis-health-logo.png'},
          { property: 'og:description', content: 'Health and technology company in San Diego specializing in software engineering, digital design, and print media for health and other industries.'},
          { property: 'og:url', content: 'https://www.sarsis.com'},
        ]);
   }

  ngOnInit() {

  }

  // hero video play button
  playVideo() {
      let vid: HTMLMediaElement = <HTMLMediaElement>document.getElementById("video");
      vid.muted = false;
      $('.video-play').fadeOut();
  }
}
