<!-- hero image and content -->
<div class="container-fluid portfolio-hero">
  <div class="row">
      <div class="col-sm-12 text-center">
          <h1>PORTFOLIO</h1>
      </div>
  </div>
</div>

<!-- portfolio -->
<div class="container portfolio">
  <div class="row featured-clients">
    <div class="col-12 col-md-4 box text-center">
      <div class="box-shadow">
        <img class="img-fluid center-block" src="/assets/images/portfolio/choose-well.jpg"/>
        <h3>Public Health</h3>
        <a class="btn view-live center-block" href="https://choosewellsandiego.org" target="_blank">View Live Site</a>
      </div>
    </div>
    <div class="col-12 col-md-4 box text-center">
      <div class="box-shadow">
        <img class="img-fluid center-block" src="/assets/images/portfolio/federation.jpg"/>
        <h3>Non Profit</h3>
        <a class="btn view-live center-block" href="https://www.chicanofederation.org/" target="_blank">View Live Site</a>
      </div>
    </div>
    <div class="col-12 col-md-4 box text-center">
      <div class="box-shadow">
        <img class="img-fluid center-block" src="/assets/images/portfolio/ucsd-ngp.jpg"/>
        <h3>University</h3>
        <a class="btn view-live center-block" href="/assets/images/portfolio/ucsd-ngp-lg.jpg" target="_blank">View Full Design</a>
      </div>

    </div>

    <div class="col-12 col-md-4 box text-center">
      <div class="box-shadow">
        <img class="img-fluid center-block" src="/assets/images/portfolio/cpapman.jpg"/>
        <h3>Medical Equipment</h3>
        <a class="btn view-live center-block" href="https://www.cpapman.com" target="_blank">View Live Site</a>
      </div>
    </div>
    <div class="col-12 col-md-4 box text-center">
      <div class="box-shadow">
        <img class="img-fluid center-block" src="/assets/images/portfolio/reflect.jpg"/>
        <h3>Door & Window Hardware</h3>
        <a class="btn view-live center-block" href="https://www.reflectwindow.com/" target="_blank">View Live Site</a>
      </div>
    </div>
    <div class="col-12 col-md-4 box text-center">
      <div class="box-shadow">
        <img class="img-fluid center-block" src="/assets/images/portfolio/new-lung.jpg"/>
        <h3>Public Health</h3>
        <a class="btn view-live center-block" href="https://www.1866newlung.com/free-materials" target="_blank">View Live Site*</a>
      </div>
    </div>
    <div class="col-sm-12 disclaimer">
      *Limited customization in SquareSpace platform only.
    </div>
  </div>

  <div class="row other-logos">
      <div class="col-sm-12 other-clients text-center">
        <h4>Other Clients</h4>
      </div>
      <div class="col-sm-4">
          <img alt="MappTrap" title="MappTrap" class="img-fluid" src="/assets/images/mapptrap-logo.jpg" />
      </div>
      <div class="col-sm-4">
          <img alt="San Diego State University" title="San Diego State University" class="img-fluid" src="/assets/images/sdsu-logo.jpg" />
      </div>
      <div class="col-sm-4">
          <img alt="Reflect Windows & Doors" title="Reflect Windows & Doors" class="img-fluid" src="/assets/images/reflect-window-logo.jpg" />
      </div>
  </div>
  <div class="row">
      <div class="col-sm-4">
          <img alt="Rent Bed Bugs Heaters" title="Rent Bed Bugs Heaters" class="img-fluid" src="/assets/images/rbbh-logo.jpg" />
      </div>

      <div class="col-sm-4">
          <img alt="National Credit Center" title="National Credit Center" class="img-fluid" src="/assets/images/ncc-logo.jpg" />
      </div>
      <div class="col-sm-4">
          <img alt="Pirch" title="Pirch" class="img-fluid" src="/assets/images/pirch-logo.jpg" />
      </div>
  </div>
</div>
