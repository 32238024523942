<!-- parallax -->
<div class="container-fluid">
      <div class="angular-hero-parallax text-center">
          <img alt="San Diego Angular Experts" title="San Diego Angular Experts" class="img-fluid center-block" src="/assets/images/angular4-blue.png" />
      </div>
</div>

<!-- row 1 -->
<div class="container-fluid">
  <div class="row">
      <div class="container angular">
          <div class="col-sm-8 text-center col-centered">
              <h1>Should I Use <strong>Angular</strong> for my New Website?</h1>
              <br />
              This article is deprecated.
              <br />
              <br />
              <p>Thinking about redoing your website? This article discusses my experience with the popular “Angular” framework people are using to develop websites that work on all devices, desktop and mobile. If you haven’t kept up, Angular 2.2 was released to production last October (2016), and we’re just now beginning to see some major applications nearing completion using the framework. We had a client willing to be the guinea pig, and they let us build their enterprise web application in Angular2, so we’ll share our experience with you.</p>
              <p>Angular2 uses a programming language called TypeScript. It’s supposed to be “superset” of javascript, meaning it’s just javascript with a few more keywords. You then use a special compiler to translate that typescript into Javascript, which is what your web browser can understand. It took me about three days to figure out how to deploy my first build. Tutorials online are still scarce. This means if your developer hasn’t used Angular2 before, don’t be surprised if it takes them a while to figure it out.</p>
              <p>Angular allows you to preload all your HTML files as text strings in a single javascript file, so the web browser doesn’t have to hit the server each time a visitor loads a new page. This allows pages to load lightning fast. This is all great, but as of Angular 2.2, there is no official support to convert HTML templates to javascript strings! So after a little digging, I found a third party gulp task <a href="https://github.com/ludohenin/gulp-inline-ng2-template" target="_blank">here</a> that will do the trick.</p>
              <p>I think one of my biggest frustrations with this Angular2 project is that because it’s so new, documentation that I find online don’t keep up with the actual Angular2 build. I read articles that are only four months old that I find out no longer applies. And the TypeScript compiler I first used when I started the project, turned out to be deprecated. Argh!</p>
              <p>So, any reason to use Angular2, you ask? Well, my final vote is still a Yes. Despite the steep learning curve, I found that towards the end of my project, after I stopped getting stuck, pages that took me 3 days to build in traditional ASP.NET server side took a little less time, and it’s very rewarding to see pages load so fast without having to put so much forethought into caching and other optimizations.</p>
          </div>
      </div>
  </div>
</div>
