<img class="img-fluid" src="/assets/images/tech2connect/Tech2Connect.png"/>
<br/>
<br/>
<div class="container">
  <h2>Downloads</h2>
  <a href="/assets/pdf/tech2connect/Version-2-IHSS-Mental-Health-and-Social-Engagement-Resource-Packet.docx.pdf">Mental Health and Social Engagement Resource Packet (PDF) </a>
  <br/>
  <a href="/assets/pdf/tech2connect/Spanish-of-Version-2-IHSS-Mental-Health-and-Social-Engagement-Resource-Packet.pdf">Paquete de Recursos de Salud Mental y Participación Social (PDF) </a>
  <br/>
  <a href="/assets/pdf/tech2connect/Scam-Prevention.pdf">Scam Prevention Tips (PDF) </a>
  <br/>
  <h2>Feedback</h2>
  <a href="http://www.surveymonkey.com/r/ZN5JJJD">Participant Satisfaction Survey</a>
  <br/>
  <a href="http://eepurl.com/iJmvrc">Subscribe to Tech2Connect</a>
  <br/>
  <h2>Tech2Connect Hotline</h2>
  <a href = "tel:619-728-4899">(619) 728-4899</a>
<br/>
<br/>
<br/>
  <div class="text-center">
    <img src="/assets/images/tech2connect/logoCOSD.png"/>
    Funding for Tech2Connect provided by the County of San Diego
  </div>
</div>
<br/>
<br/>
<br/>
<br/>
