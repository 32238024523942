<!-- hero video -->
<div class="container-fluid">
  <div class="row home-video">
      <video width="100%" autoplay muted id="video" controls onloadedmetadata="this.muted = true">
          <source src="https://images.sarsis.com/videos/Sarsis-Web-Design-From-Concept-to-Launch.mp4" type="video/mp4">
      </video>
      <div class="hero-content">
          <h3 class="video-play" (click)="playVideo()">Click for Audio <i class="fa fa-volume-up" aria-hidden="true"></i></h3>
      </div>
  </div>
</div>

<!-- Container (Services Section) -->
<div id="services" class="container services text-center">
  <div class="row">

      <div class="col-sm-4 bg-grey">
        <a routerLink="/web-development">
          <i class="fa fa-desktop" aria-hidden="true"></i>
          <h4>WEB DEVELOPMENT</h4>
          <p>Front End, Back-End, Admin Panel, Database Design</p>
        </a>
      </div>


      <div class="col-sm-4">
        <a routerLink="/single-page-application">
          <i class="fa fa-file-code-o" aria-hidden="true"></i>
          <h4>SINGLE PAGE APPLICATION</h4>
          <p>Angular Web Applications</p>
        </a>
      </div>


      <div class="col-sm-4 bg-grey">
        <a routerLink="/e-commerce">
          <i class="fa fa-shopping-cart" aria-hidden="true"></i>
          <h4>E-COMMERCE</h4>
          <p>Sarsis, WooCommerce, Shopify, Magento, 3Dcart</p>
        </a>
      </div>

  </div>
  <div class="row">
    <div class="col-sm-4">
      <a routerLink="/seo">
        <i class="fa fa-search" aria-hidden="true"></i>
        <h4>SEARCH ENGINE OPTIMIZATION</h4>
        <p>Onsite and Offsite SEO audit and implementations</p>
      </a>
    </div>
    <div class="col-sm-4 bg-grey">
      <a routerLink="/ui-ux">
        <i class="fa fa-object-group" aria-hidden="true"></i>
        <h4>UI/UX</h4>
        <p>Simple, Clean, Sleek, Minimal</p>
      </a>
    </div>
    <div class="col-sm-4">
      <a routerLink="/ui-ux">
        <i class="fa fa-paint-brush" aria-hidden="true"></i>
        <h4>Graphic Design</h4>
        <p>Mockups, Wireframe, Moodboard, and Logos</p>
      </a>
    </div>

  </div>
</div>

<!-- row 1 -->
<div class="container-fluid intro">
  <div class="container">
      <div class="row">
          <div class="col-md-4">
              <img class="img-fluid" alt="Quan with Chicano Federation Team" title="Quan with Chicano Federation Team" src="./assets/images/quan-y-chicano-federation-staff.jpg">
              <h3 class="pt-1">Collaborative, Friendly</h3>
              <p>We get things done through personal collaboration with clients, whether sitting down casually in our office or over dinner and a couple beers on us at a local hipster brewery down the street. We are humbled by our amazing clients, and quite frankly, we learn a lot from them.</p>
          </div>
          <div class="col-md-4">
              <img class="img-fluid" alt="Sarsis Interviewing an Assisted Living Home" title="Sarsis Interviewing an Assisted Living Home" src="./assets/images/video-interview.jpg">
              <h3 class="pt-1">Diverse, Multiskilled</h3>
              <p>Every Sarsis team member contributes essential services to our clients' mission. Sarsis is a unique brew of software engineers, public health professionals, graphic artists, and marketers. Each of our personal and cultural backgrounds is just as diverse as our different skills.</p>
          </div>
          <div class="col-md-4">
              <img class="img-fluid" alt="Sarsis Office" title="Sarsis Office" src="./assets/images/experienced-team.jpg">
              <h3 class="pt-1">Dynamic, Adaptive</h3>
              <p>Sarsis team members have versatile skills to rapidly adapt to changing local and global situations. Our engineer is also a photographer. Our graphic designer is also a video content creator.  Our test engineer also writes sales scripts.</p>
          </div>
      </div>
  </div>
</div>

<!-- parallax -->
<div class="container-fluid p-0">
    <div class="row2-parallax">
        <div class="caption">
            <div class="container">
              <div class="row">
                <div class="col-sm-8 col-centered">
                    <h1>Bringing Technology and Health Together</h1>
                    <h4></h4>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>

<!-- row 3 -->
<div class="container row3">
  <div class="row">
      <div class="col-12 col-sm-6">
              <img class="img-fluid" alt="Cafe Calabria" title="Cafe Calabria" src="./assets/images/invested-in-community.jpg">
              <h3>Invested in the Community</h3>
              <p>We are a locally focused company, and half of our business originates in San Diego County. We do business with local vendors whenever possible because we believe when the community thrives, we thrive.</p>
      </div>
      <div class="col-12 col-sm-6">
              <img class="img-fluid" alt="Volunteering Meals on Wheels" title="Volunteering Meals on Wheels" src="./assets/images/quan-pancho-meals-on-wheels.jpg">
              <h3>Service before Profits</h3>
              <p>Whether it’s delivering meals for seniors on Fridays, or staying in late to do something extra for a client, Sarsis has a culture of putting community service and client satisfaction before profits.</p>

      </div>
  </div>
</div>

<!-- row4 -->
<div class="container technology">
  <div class="row">
      <div class="col-sm-12 text-center">
          <h1>Technologies We Use</h1>
          <h3>Sarsis has a broad range of skills in different technologies.</h3><br />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="Bootsrap" title="Bootsrap" class="img-fluid center-block" src="./assets/images/bootstrap-case.jpg" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="C#/.NET" title="C#/.NET" class="img-fluid center-block" src="./assets/images/csharp-case.jpg" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="Angular 2/4" title="Angular 2/4" class="img-fluid center-block" src="./assets/images/angular4.png" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="Javascript" title="Javascript" class="img-fluid center-block" src="./assets/images/js-logo-case.jpg" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="HTML/HTML5" title="HTML/HTML5" class="img-fluid center-block" src="./assets/images/html-logo-case.jpg" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="Amazon Web Services" title="Amazon Web Services" class="img-fluid center-block" src="./assets/images/aws-color.png" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="CSS/CSS3" title="CSS/CSS3" class="img-fluid center-block" src="./assets/images/css3.jpg" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="Amazon S3" title="Amazon S3" class="img-fluid center-block" src="./assets/images/aws-s3.jpg" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="ASP.NET" title="ASP.NET" class="img-fluid center-block" src="./assets/images/aspnet.jpg" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="Azure Database" title="Azure Database" class="img-fluid center-block" src="./assets/images/azure-db.png" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="Entity Framework" title="Entity Framework" class="img-fluid center-block" src="./assets/images/entity-framework.jpg" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="jQuery" title="jQuery" class="img-fluid center-block" src="./assets/images/jQuery.jpg" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="NodeJS" title="NodeJS" class="img-fluid center-block" src="./assets/images/nodejs.png" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="PHP" title="PHP" class="img-fluid center-block" src="./assets/images/php.jpg" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="MySQL" title="MySQL" class="img-fluid center-block" src="./assets/images/mysql.jpg" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="RESTful API" title="RESTful API" class="img-fluid center-block" src="./assets/images/restfulapi.jpg" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="MySQL" title="MySQL" class="img-fluid center-block" src="./assets/images/python.jpg" />
      </div>
      <div class="col-6 col-md-2 text-center">
          <img alt="RESTful API" title="RESTful API" class="img-fluid center-block" src="./assets/images/mongodb.jpg" />
      </div>
  </div>
</div>
