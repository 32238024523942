<!-- hero image parallax and content -->
<!-- parallax -->
<div class="container-fluid p-0">
    <div class="ecommerce-hero-parallax">
        <div class="ecommerce-caption">
            <div class="container">
                <h1>E-COMMERCE</h1>
                <h3>Sarsis is a suite of integrated components that work well together and is designed to make implementation simple. Its strength lies in three properties: it’s unified, flexible, and has a simple architecture.</h3>
            </div>
        </div>
    </div>
</div>

<!-- row 1 -->
<div class="container ecommerce">
  <div class="row">
      <div class="col-sm-6 col-md-4">
        <h2>SARSIS: <strong>RAZOR Template</strong></h2>
        <p>MVC based front end uses a powerful view engine to display your merchandise the way you want without expensive programming.</p>
      </div>
      <div class="col-sm-6 col-md-4">
        <h2>SARSIS: <strong>REST API</strong></h2>
        <p>Restful API displays your product data and common ecommerce functions such as a shopping cart and checkout system in a secure, ready to use format. The REST API talks directly to the RAZOR Template or any client that can send and receive XML or json.</p>
      </div>
      <div class="col-sm-6 col-md-4">
        <h2>SARSIS: <strong>Business Engine</strong></h2>
        <p>If the REST API service doesn’t cut it for you, our powerful, well designed business engine will give you direct access to your website’s code.</p>
      </div>
  </div>
  <div class="row">
      <div class="col-sm-6 col-md-4">
          <h2>SARSIS: <strong>SpeedCache</strong></h2>
          <p>Tap into our cluster of high power caching servers with SpeedCache. Your entire product catalog is loaded into memory for lightning fast page loads. SpeedCache is integrated with the Business Engine and Data service, but also works as a stand alone to speed up just about anything you want!</p>
      </div>
      <div class="col-sm-6 col-md-4">
          <h2>SARSIS: <strong>Data</strong></h2>
          <p>Elegantly wrapped in a decoupled repository pattern, Sarsis Data lets you build fast, custom queries on returned data that directly access the database the right way without hacking into the application.</p>
      </div>
      <div class="col-sm-6 col-md-4">
          <h2><strong>Unlimited Scalability</strong></h2>
          <p>When we say unlimited scalability, we mean it. Our ecommerce platform is designed to be easily replicated on as many web servers as needed to maintain throughput. Our caching servers can also be replicated as needed, eliminating the traditional bottleneck of database servers that can’t easily be scaled. When your online sales grow, our capacity grows with you.</p>
      </div>
  </div>
</div>
