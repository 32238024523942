import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, take, single } from 'rxjs/operators';
import { SocialPostModel } from './socialpost.model';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaFeedService {
  //http://fetchrss.com/rss/5e06b9a18a93f8696b8b45675f80fb4f3bcb373d3f4b3e42.json
  constructor(private http: HttpClient) { }

  async getSocialPosts(): Promise<SocialPostModel[]> {
    // Parameters obj-
    let params: URLSearchParams = new URLSearchParams();

    let headers = new HttpHeaders({
        'Accept': 'application/json'
    });

    let options = {
        headers: headers,
        search: params
    };

    return [];
  }
}
