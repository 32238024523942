import { Component, OnInit, Directive} from '@angular/core';

// service
import { BlogPostService } from './blog-post.service';

// model
import { Articles } from './article.model';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.css']
})
export class BlogPostComponent implements OnInit {

  articles: Articles[];

  constructor(private blogPostService: BlogPostService) { }

  articleId: number;

  ngOnInit() {
    this.blogPostService.getBlogArticles(this.articleId).subscribe(articles => this.articles = articles);
  }

}
