<!-- hero image parallax and content -->
<!-- parallax -->
<div class="container-fluid p-0">
    <div class="contact-hero-parallax">
        <div class="contact-caption">
            <h1>CONTACT INFORMATION</h1>
        </div>
    </div>
</div>

<!-- row 1 -->
<div class="container contact">
  <div class="row">
      <div class="col-sm-7 contact-info">
          <div class="col-sm-6">
              <h2>Sarsis office</h2>
              <br />
              <p>
              <strong>North Park</strong><br />
              3180 University Ave #215<br />
                 San Diego, CA 92104</p>
              <h2>Sales</h2>
              <p>
                 <strong>Phone:</strong> <a href="tel:8888823266"> 888.882.ECOM (3266)</a><br />
                 <strong>Email:</strong> <a href="mailto:sales@sarsis.com"> sales&#64;sarsis.com</a><br />
              </p>
          </div>
          <div class="col-sm-6">
              <h2>Support</h2>
              <br />
              <p>
                  <strong>Email:</strong> <a href="mailto:support@sarsis.com"> support&#64;sarsis.com</a><br />
                  <strong>Phone:</strong> <a href="tel:7605454449"> 760.545.4449</a>
              </p>
              <h2>Engineer support</h2>
              <p>
                  <strong>Email:</strong> <a href="mailto:engineer@sarsis.com"> engineer&#64;sarsis.com</a>
              </p>
          </div>
      </div>
      <div class="col-sm-5 contact-office d-none d-sm-block">
          <div class="offset-wrapper">
              <img alt="Sarsis Web App Development Services" title="Sarsis Web App Development Services" class="img-fluid" src="/assets/images/3180-university-215.jpg" />
          </div>
      </div>
  </div>
</div>

<!-- hero image parallax and content -->
<!-- parallax -->
<div class="container-fluid contact-form-parallax">
  <div class="row">
      <div class="container">
              <h1 class="text-center">REACH OUT TO US</h1>
              <h3 class="text-center">We'd love to hear from you!</h3><br />
              <p class="text-center">* Indicates required field</p>
              <br />
              <div class="col-12 col-sm-8 col-md-4 offset-sm-2 offset-md-4 contact-form">
                  <form #contactForm="ngForm">
                      <div class="form-group">
                          <input type="text" class="form-control input-lg" [(ngModel)]="contactViewModel.FullName" name="FullName" #fullName="ngModel" placeholder="Full name *" required>
                          <div *ngIf="fullName.errors && (fullName.dirty || fullName.touched)">
                              <div [hidden]="!fullName.errors['required']">
                                  <span class="error-message">Please enter your name</span>
                              </div>
                          </div>
                      </div>
                      <div class="form-group">
                          <input type="text" class="form-control input-lg" [(ngModel)]="contactViewModel.Email" name="Email" #email="ngModel" placeholder="Email address *" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$" required>
                          <div *ngIf="email.errors && (email.dirty || email.touched)">
                              <div [hidden]="!email.errors['pattern']">
                                  <span class="error-message">Please enter a valid email address</span>
                              </div>
                          </div>
                      </div>
                      <div class="form-group">
                          <input type="text" class="form-control input-lg" [(ngModel)]="contactViewModel.Phone" name="Phone" #phone="ngModel" placeholder="Contact number (optional)">
                      </div>
                      <div class="form-group">
                          <textarea class="form-control" rows="5" [(ngModel)]="contactViewModel.Message" name="Message" #message="ngModel" placeholder="Enter message *" minlength="10" required></textarea>
                          <div *ngIf="message.errors && (message.dirty || message.touched)">
                              <div [hidden]="!message.errors['required'] && !message.errors['minlength']">
                                  <span class="error-message">Please enter at least 10 characters</span>
                              </div>
                          </div>
                      </div>
                      <input type="button" class="btn btn-lg" value="SUBMIT MESSAGE" [disabled]="!contactForm.form.valid" (click)="submitContact()"/>
                  </form>
              </div>
      </div>

  </div>
</div>
