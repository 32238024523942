import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';


// components
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';

import { AngularComponent } from './angular/angular.component';
import { CareersComponent } from './careers/careers.component';
import { CaseStudiesComponent } from './case-studies/case-studies.component';
import { CategoryComponent } from './category/category.component';
import { ContactComponent } from './contact/contact.component';
import { ECommerceComponent } from './e-commerce/e-commerce.component';
import { HowWeBuildValueComponent } from './how-we-build-value/how-we-build-value.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { QuoteComponent } from './quote/quote.component';
import { SeoComponent } from './seo/seo.component';
import { SinglePageApplicationComponent } from './single-page-application/single-page-application.component';
import { UiUxComponent } from './ui-ux/ui-ux.component';
import { WhyChooseUsComponent } from './why-choose-us/why-choose-us.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { BlogComponent } from './blog/blog.component';
import { BlogPostComponent } from './blog-post/blog-post.component';
import { RcfeSocialMediaComponent } from './rcfe-social-media/rcfe-social-media.component';
import { CoronavirusComponent } from './coronavirus/coronavirus.component';

// services
import { ContactService } from './contact/contact.service';
import { QuoteService } from './quote/quote.service';
import { BlogService } from './blog/blog.service';
import { BlogPostService } from './blog-post/blog-post.service';
import { SocialMediaFeedService } from './footer/socialmediafeed.service';
import { SdlhTechPublicHealthComponent } from './blog/sdlh-tech-public-health/sdlh-tech-public-health.component';
import { Tech2connectComponent } from './tech2connect/tech2connect.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';

//import { CustomRequestOptions } from './custom-base-request';


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HomeComponent,
    AngularComponent,
    CareersComponent,
    CaseStudiesComponent,
    CategoryComponent,
    ContactComponent,
    ECommerceComponent,
    HowWeBuildValueComponent,
    PortfolioComponent,
    QuoteComponent,
    SeoComponent,
    SinglePageApplicationComponent,
    UiUxComponent,
    WhyChooseUsComponent,
    PageNotFoundComponent,
    WebDevelopmentComponent,
    BlogComponent,
    BlogPostComponent,
    CoronavirusComponent,
    RcfeSocialMediaComponent,
    SdlhTechPublicHealthComponent,
    Tech2connectComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    HeaderComponent
  ],
  providers: [
    ContactService,
    QuoteService,
    BlogService,
    BlogPostService,
    SocialMediaFeedService,
    provideClientHydration(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
