import { Component, OnInit, Directive} from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  constructor(meta: Meta, title: Title) {

        title.setTitle('Sarsis | Portfolio');

            meta.addTags([
              { name: 'author',   content: 'sarsis.com'},
              { name: 'keywords', content: 'Enterprise Web Development, Web Development, E-commerce, Angular 2/4 Service, Single Page Application Development, C#, .NET, Javascript, HTML, CSS, Bootstrap 3/4'},
              { name: 'description', content: 'Enterprise Web Application Development Company located in San Diego' }
            ]);
       }

  ngOnInit() {
  }

}
