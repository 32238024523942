import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';



import { AppSettings } from '../appSettings';

// model
import { Articles } from './article.model';

@Injectable()
export class BlogPostService {

  constructor(private http: HttpClient) { }

        getBlogArticles(articleId: number): Observable<Articles[]> {
            // Parameters obj-
            let params: URLSearchParams = new URLSearchParams();

            let headers = new HttpHeaders({
                'Accept': 'application/json'
            });

            let options = {
                headers: headers,
                search: params
            };

            return this.http.get<Articles[]>('/GetArticle/' + articleId, options);
        }

        // get subscriptions options
      //  getBlogArticlesMock(): Observable<BlogArticles[]> {

           // return Observable.of(SELECTMEMBERPRODUCTS);

      //  }

}
