<!-- hero image and content -->
<div class="container-fluid case-study-hero">
  <div class="row">
      <div class="col-sm-12 text-center">
          <h1>CASE STUDIES</h1>
      </div>
  </div>
</div>

<!-- case studies -->
<div class="tab"><!-- tab menu -->
  <div class="container">
      <div class="row">

        <div class="col-lg-12 col-md-12 col-sm-12 ">
          <button class="tablinks active" onclick="openCity(event, 'rbbh')" id="defaultOpen">Service Industry</button>
          <button class="tablinks" onclick="openCity(event, 'reflect')">E-Commerce</button>
          <button class="tablinks" onclick="openCity(event, 'mapptrap')">Dashboard Design</button>
      </div>
    </div>
  </div>
</div>

<div id="rbbh" class="tabcontent">
  <div class="case-wrapper rbbh-ver2">
    <!-- Project Title Section -->
    <div class="row case-study-wff ">
      <div class="col-lg-12 case-study-title">
        <h6 class="case-study-banner">Service Industry</h6>
        <h3 class="case-study-title">Rent Bed Bugs Heaters</h3>
        <div class="mark"></div>
        <p class="case-study-p">A central piece of the website required <br class="mobile-hidden">
          a Google Maps API integration<br class="mobile-hidden">
          to allow visitors to find a provider by zip code.<br class="mobile-hidden">
          The website was developed to run purely on the cloud (PaaS),<br class="mobile-hidden">
          which the client was enthusiastic about because there are no servers to maintain.
        </p>
      </div>
      <div class="col-lg-4 case-study-right-col">
      </div>
    </div>

    <!-- Before and After Images -->
    <div class="wff-imgs-wrapper ">
      <div class="row after-mockup">
        <div class="col-lg-2 col-md-1 col-sm-1"></div>
        <div class="col-lg-4 col-md-5 col-sm-5 col-xs-12">
          <div class="rbbh1-img hand-over-img rbbh-img">
            <img class="hand-select" src="/assets/images/hand-select.png" />
          </div>
        </div>
        <div class="col-lg-4 col-md-5 col-sm-5 col-xs-12">
          <div class="rbbh4-img hand-over-img rbbh-img">
            <img class="hand-select" src="/assets/images/hand-select.png" />
          </div>
        </div>
      </div>
    </div>

    <!-- Project Footer Section -->
    <div class="wff-bottom">
      <p class="bottom-logo"><img alt="Rent Bed Bugs Heaters" title="Rent Bed Bugs Heaters" class="img-responsive center-block rbbh-logo" src="/assets/images/rbbh-logo.png" width="150"/></p>
      <div class="container">
        <h4>Background</h4>
        <p class="case-study-p-bottom">The client was a manufacturer that sold equipment to pest control service providers.
          They wanted to market their product, a DIY whole home heater to treat bed bug infestations.
          Their vision was to build a website for homeowners to rent out the heaters through a network or service providers.
          A central piece of the website required a Google Maps API integration to allow visitors to find a provider by zip code.
          The homeowner goes through an eight step workflow to book an appointment.
          They choose the heater package for the size of their home, pick the service dates, and optionally choose “white glove” service.
          The requirement was the website had to be optimized for phones and desktop, and one codebase should work for both.
          The client did not want to maintain two separate websites. The site had to load quickly.
          Business logic had to be written to display only the heat packages that each provider offered, since each service provider offered different services.</p>
          <div class="row technology">
            <div class="col-lg-2"></div>
            <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
              <img alt="Bootstrap" title="Bootstrap" class="img-responsive center-block" src="/assets/images/bootstrap-logo1.png" />
            </div>
            <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
              <img alt="C#/.NET" title="C#/.NET" class="img-responsive center-block" src="/assets/images/csharp-logo1.png" />
            </div>
            <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
              <img alt="Angular 2" title="Angular 2" class="img-responsive center-block" src="/assets/images/angular-logo.png" />
            </div>
            <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
              <img alt="HTML" title="HTML" class="img-responsive center-block" src="/assets/images/html-logo1.png" />
            </div>
          </div>
        <h4>Technology Challenges</h4>
        <p class="case-study-p-bottom">The client already had a web design for both mobile and desktop, and they were drastically different, making it hard to do a responsive site.
          We worked through each page, and identified visual elements that could be made responsive.
          For elements that were too different, we used CSS and media queries and detected the width of the browser to show the correct visual element for the screen size.
          The site had to load as fast as possible. We chose to write the application in Angular2 as a Single Page Application (also known as SPA App).
          Using this technology, the entire application was loaded as javascript to the visitor’s browser.
          As the visitor navigates to a new page, the content is rendered instantly because it is already preloaded on the client browser.
          This saves a round trip to the web server, and is a great user experience for the visitor.
          It took several rounds of optimization to achieve the page load times we desired.
          The problem with Angular2 is that the site is slow to load the first page, as the entire site is downloaded to the browser.
          First we tried bundling the application into just two javascript files.
          That helped reduce the load time a bit, but the javascript still had to be compiled JIT (just in time) before rendering on the browser.
          We proceeded to AoT (ahead of time) compilation, which precompiled the site into a single, ready to display javascript file.
          This substantially reduced the load time, but we wanted to do even better. We reordered scripts in the index.html page, so that the important scripts loaded first.
          We also analyzed the larger CSS files that the browser could not download asynchronously, and put those at the end.</p>
          <div class="row technology">
            <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
              <img alt="Google Map API" title="Google Map API" class="img-responsive center-block" src="/assets/images/google-map-api.png" />
            </div>
            <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
              <img alt="Entity Framework" title="Entity Framework" class="img-responsive center-block" src="/assets/images/entity-framework-logo.png" />
            </div>
            <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
              <img alt="Azure DB" title="Azure DB" class="img-responsive center-block" src="/assets/images/azure-db.png" />
            </div>
            <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
              <img alt="Javascript" title="Javascript" class="img-responsive center-block" src="/assets/images/js-logo.png" />
            </div>
          </div>
        <h4>A True Cloud App</h4>
        <p class="case-study-p-bottom">This website was developed to run purely on the cloud (PaaS), which the client was enthusiastic about because there are no servers to maintain.
          We wanted to get completely away from operating systems and virtual machines, since those still required patching and maintenance.
          Instead, we opted to use Azure’s AppService, which is a web hosting container where we could just drop the website in and it will run.</p>
        <h4>A Complex, Multi-Tiered Application</h4>
        <p class="case-study-p-bottom case-study-list-padding">
              &#8226; 28 UI components in TypeScript (Angular2)<br />
              &#8226; 96 RESTful API Endpoint operations<br />
              &#8226; 150+ CRUD Repository EntityFramework Operations<br />
              &#8226; 150+ POCO Models<br />
              &#8226; Azure CloudQueue for backend services
        </p>
        <h4>Value Delivered to the Client</h4>
        <p class="case-study-p-bottom case-study-list-padding">
              &#8226; Developed website in Angular2 for extremely fast load times on all devices<br />
              &#8226; Decoupled Service Layer to make HTML updates easy without interfering with critical code<br />
              &#8226; Hosted on Microsoft Azure, so there are no servers nor virtual machines to maintain<br />
              &#8226; Performed multiple rounds of optimization to reduce page load times to the bare minimum
        </p>
      </div>
    </div>

  </div><!-- END case-wrapper rbbh-ver2 -->

</div><!-- END rbbh -->


<div id="reflect" class="tabcontent">
  <!-- Water filters fast -->
  <div class="case-wrapper wff-ver1">
    <!-- Project Title Section -->
    <div class="row case-study-wff ">
      <div class="col-lg-8 col-md-12 case-study-title">
        <h6 class="case-study-banner">E-Commerce</h6>
        <h3 class="case-study-title">Water Filters Fast</h3>
        <div class="mark"></div>
        <p class="case-study-p">Created a simple, clean design to allow customers <br class="mobile-hidden">
          to quickly find the water filter they need and make a purchase.
        </p>
      </div>
      <div class="col-lg-4 col-md-12 case-study-right-col">
        <img src="/assets/images/responsive.png" />
      </div>
    </div>

    <!-- Before and After Images -->
    <div class="wff-imgs-wrapper">
      <div class="row wff-imgs">
        <div class="col-lg-2 col-md-1 col-sm-1"></div>
        <div class="col-lg-4 col-md-5 col-sm-5">
          <div class="wff-img1-before wff-img-col">
            <div class="overlay">
              <div class="overlay-text">Adobe XD Mockup</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-5 col-sm-5">
          <div class="wff-img1-after wff-img-col">
            <div class="overlay">
              <div class="overlay-text">Perfect HTML</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row wff-imgs">
        <div class="col-lg-2 col-md-1 col-sm-1"></div>
        <div class="col-lg-4 col-md-5 col-sm-5">
          <div class="wff-img2-before wff-img-col">
            <div class="overlay">
              <div class="overlay-text">Adobe XD Mockup</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-5 col-sm-5">
          <div class="wff-img2-after wff-img-col">
            <div class="overlay">
              <div class="overlay-text">Perfect HTML</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Project Footer Section -->
    <div class="wff-bottom">
      <p class="bottom-logo"><img alt="Waterfiltersfast" title="Water Filters Fast" class="img-responsive center-block wff-logo-img" src="/assets/images/wff-logo.png" width="200" /></p>
      <h4>Platform Used</h4>
      <p>3D Cart with API customization</p><br>
      <h4>Unique Challenges</h4>
      <p>Used REST API to develop a custom Add To Cart feature not available with a regular 3D Cart implementation.</p>
    </div>
  </div>

  <!-- Reflect -->
  <div class="case-wrapper reflect-ver2">
    <!-- Project Title Section -->
    <div class="row case-study-wff ">
      <div class="col-lg-12 case-study-title">
        <h6 class="case-study-banner">E-Commerce</h6>
        <h3 class="case-study-title">Reflect</h3>
        <div class="mark"></div>
        <p class="case-study-p">This client started with us as a small online store <br class="mobile-hidden">selling window and door hardware.<br class="mobile-hidden">
          We worked closely with the business <br class="mobile-hidden">to grow the site to a large, complex site <br class="mobile-hidden">that is profitable and a major source for revenue for the owners.
        </p>
      </div>
    </div>

    <!-- Before and After Images -->
    <div class="wff-imgs-wrapper ">
      <div class="row after-mockup">
        <div class="col-lg-2 col-md-1 col-sm-1"></div>
        <div class="col-lg-4 col-md-5 col-sm-5">
          <div class="reflect1-img hand-over-img reflect-img">
            <img class="hand-select" src="/assets/images/hand-select.png" />
          </div>
        </div>
        <div class="col-lg-4 col-md-5 col-sm-5">
          <div class="reflect2-img hand-over-img reflect-img">
            <img class="hand-select" src="/assets/images/hand-select.png" />
          </div>
        </div>
      </div>
    </div>

    <!-- Project Footer Section -->
    <div class="wff-bottom">
      <p class="bottom-logo"><img alt="Reflect Windows & Doors" title="Reflect Windows & Doors" class="img-responsive center-block reflect-logo" src="/assets/images/reflect-logo.png" width="200" /></p>
      <div class="container">
        <h4>A Complex Data Migration</h4>
        <p class="case-study-p-bottom">We took over the website from a previous vendor that did not have ecommerce capabilities.
          The site was a database driven catalog, and customers placed an order by filling out a form.
          We were given a couple spreadsheets with the products exported from a proprietary database.
          There was no consistent structure, so the data required sanitizing before it could be imported into our system.
           We wrote a script that analyzed various fields in each row to group the product variations together into a base product.</p>
        <div class="row technology">
          <div class="col-lg-2"></div>
          <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
            <img alt="Bootstrap" title="Bootstrap" class="img-responsive center-block" src="/assets/images/bootstrap-logo1.png" />
          </div>
          <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
           <img alt="C#/.NET" title="C#/.NET" class="img-responsive center-block" src="/assets/images/csharp-logo1.png" />
          </div>
          <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
           <img alt="Amazon Web Services" title="Amazon Web Services" class="img-responsive center-block" src="/assets/images/aws-logo.png" />
         </div>
         <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
           <img alt="HTML" title="HTML" class="img-responsive center-block" src="/assets/images/html-logo1.png" />
         </div>
        </div>
        <h4>Using Queues to Upload Product Images</h4>
        <p class="case-study-p-bottom">The client regularly uploaded hundreds of product images through the web based admin portal.
          The product images had to be resized and cropped to be web ready. The existing system had a process that resized the images directly on the web server.
          This caused performance issues for customers on the website when the resize task used up all the web server’s resources.
          We deployed several cloud based services to address this issue: Amazon’s SQS Queue, S3 Storage, and Microsoft Azure’s WebJobs.
          All the resource intensive work was offloaded away from the web server. With this new architecture, the the web server’s only job is to upload the images to an S3 storage bucket.
           The S3 bucket is configured to insert a message to the queue when an image is written to it. An Azure WebJob, which is just a continuously running program on the cloud, monitors the queue.
           When it sees a message, it resizes the image, and stores it into a final resting place on a content delivery network.</p>
        <h4>Challenging UX Implementation</h4>
        <p class="case-study-p-bottom">The online store sold products with varying schemas for each product.
          A unified template for all products was not possible, so we had to design a highly customized product page
          that optimally displayed each product on all devices, including phones, tablets, and desktop.
        The design called for a unique, responsive menu system that optimized organizing products that were drastically different from each other.
        Some menu items were text, while others had an image. This also needed to translate to a clean, easy to navigate mobile menu.
        We built a custom CSS menu based on the bootstrap framework that met the client’s complex specifications.</p>
        <div class="row technology">
          <div class="col-lg-2 col-sm-4 col-xs-4 text-center">
            <img alt="Amazon S3" title="Amazon S3" class="img-responsive center-block" src="/assets/images/aws-s3-logo.png" />
          </div>
          <div class="col-lg-2 col-sm-4 col-xs-4 text-center">
            <img alt="Entity Framework" title="Entity Framework" class="img-responsive center-block" src="/assets/images/entity-framework-logo.png" />
          </div>
          <div class="col-lg-2 col-sm-4 col-xs-4 text-center">
            <img alt="Azure DB" title="Azure DB" class="img-responsive center-block" src="/assets/images/azure-db.png" />
          </div>
          <div class="col-lg-2 col-sm-4 col-xs-4 text-center">
            <img alt="ASP.NET" title="ASP.NET" class="img-responsive center-block" src="/assets/images/asp-logo.png" />
          </div>
          <div class="col-lg-2 col-sm-4 col-xs-4 text-center">
            <img alt="CSS3" title="CSS3" class="img-responsive center-block" src="/assets/images/css3-logo.png" />
          </div>
          <div class="col-lg-2 col-sm-4 col-xs-4 text-center">
            <img alt="Javascript" title="Javascript" class="img-responsive center-block" src="/assets/images/js-logo.png" />
          </div>
        </div>
        <h4>Custom CMS Tools</h4>
        <p class="case-study-p-bottom">Due to the complexity of the site, we built custom CMS (Content Management System) tools to enable the business owner to effectively manage their site.
          We built a tool to streamline the process of importing products to the site, and automatically refresh the cache behind the scenes.</p>
        <h4>Multiple Currency Processing</h4>
        <p class="case-study-p-bottom">The business straddled the United States and Canada, and they wanted a local, feel-at-home experience for the customers on both sides of the border.
          A requirement was that customers should be able to checkout in their local currency.
          We developed a custom checkout process that integrated with two separate merchant accounts, one processing the Canadian Dollar, and the other the US Dollar.
          The website used the customer’s IP address to automatically show prices in their local currency.
          When they check out, the ecommerce platform automatically sends the transaction to the local payment gateway.
          We expanded this functionality to PayPal orders as well, so that PayPal also processes in the correct currency.
          This was a significant win for the client, because it allowed the Canadian company to expand to the United States and quadruple its business.</p>
        <h4>Value Delivered to the Client</h4>
        <p class="case-study-p-bottom case-study-list-padding">
          &#8226; Handled a complex migration of thousands of SKUs from a legacy catalog into a well structured database<br />
          &#8226; Built a sophisticated front end that was optimized to make it easy for customers to find products by using a custom made navigation menu and product layout pages.<br />
          &#8226; Multi-national Currency processing allowed the company to break into the US market ahead of its competitors.<br />
          &#8226; Utilized Queues to offload backend work away from the web server to avoid needing to purchase additional hardware.
        </p>
      </div>
    </div>

  </div>
</div>


  <!-- Mapp Trap -->
  <div id="mapptrap" class="tabcontent">
    <div class="case-wrapper mapp-ver2">
      <!-- Project Title Section -->
      <div class="row case-study-wff ">
        <div class="col-lg-12 case-study-title">
          <h6 class="case-study-banner">Dashboard Design</h6>
          <h3 class="case-study-title">Mapp Trap</h3>
          <div class="mark"></div>
          <p class="case-study-p">The biggest win for the client was <br class="mobile-hidden">
            Sarsis’ close support with the in-house dev team.<br>
              <i>“It felt like we were working with our own team, not an outsource agency”.</i><br>
              <i>– Barry Solomon, CTO</i>
          </p>
        </div>
        <div class="col-lg-4 case-study-right-col">

      </div>
    </div>

    <!-- Before and After Images -->
    <div class="wff-imgs-wrapper ">
      <div class="row after-mockup">
        <div class="col-lg-2 col-md-1 col-sm-1"></div>
        <div class="col-lg-4 col-md-5 col-sm-5">
          <div class="mapp1-img hand-over-img mapp-img">
            <img class="hand-select" src="/assets/images/hand-select.png" />
          </div>
        </div>
        <div class="col-lg-4 col-md-5 col-sm-5">
          <div class="mapp2-img hand-over-img mapp-img">
            <img class="hand-select" src="/assets/images/hand-select.png" />
          </div>
        </div>
      </div>
    </div>

    <!-- Project Footer Section -->
    <div class="wff-bottom">
      <p class="bottom-logo"><img alt="Mapptrap" title="Mapptrapp" class="img-responsive center-block mapp-logo" src="/assets/images/mapptrap-logo-case.png" width="250" /></p>
      <div class="container">
        <h4>Developing an Administration Dashboard</h4>
        <p><i>“It felt like we were working with our own team, not an outsource agency” <span class="nextline">– Barry Solomon, CTO</span></i></p>
        <p class="case-study-p-bottom">Scenario: A client already had mockups for a dashboard,
          but needed to outsource the development because their in-house team did not have extra capacity to take on more work.
        We were tasked with taking the mockup, and turning it into a clickable, responsive front end, and building a skeleton REST WebAPI service layer that passes mock data to the drive the UI.
        The in-house team would then integrate their business logic into the scaffolded API to complete the application.
        This strategy was efficient because it lets the client’s in-house developers focus on their proprietary business logic, while offloading the general front-end development to an agency.</p>
        <div class="row technology">
          <div class="col-lg-2"></div>
          <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
            <img alt="Bootstrap" title="Bootstrap" class="img-responsive center-block" src="/assets/images/bootstrap-logo1.png" />
          </div>
          <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
            <img alt="C#/.NET" title="C#/.NET" class="img-responsive center-block" src="/assets/images/csharp-logo1.png" />
          </div>
          <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
            <img alt="CSS3" title="CSS3" class="img-responsive center-block" src="/assets/images/css3-logo.png" />
          </div>
          <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
            <img alt="HTML" title="HTML" class="img-responsive center-block" src="/assets/images/html-logo1.png" />
          </div>
        </div>
        <h4>Highlights</h4>
        <p class="case-study-p-bottom mapp-bottom-list">
          <span>&#8226; 26 Data Driven Pages</span>
          <span class="nextline">&#8226; Delivered in 4 weeks</span>
          <span class="nextline">&#8226; Formal Code Review with Client</span>
          <span class="nextline">&#8226; Fully supported in-house team</span>
        </p>
        <h4>Building Trust</h4>
        <p class="case-study-p-bottom">The biggest win for the client was Sarsis’ close support with the in-house dev team.
          “It felt like we were working with our own team, not an outsource agency”.
          Even though the client was a thousand miles away, we collaborated via screen sharing sessions to transfer our knowledge and expertise,
          and worked as a team to get their legacy code integrated into the new application we built.
          The client benefited from both the flexibility of on-demand consulting, and the casual working relationship of an in-house team working on the same roof.
          Sarsis continues to provide consulting services to MappTrap.</p>
          <div class="row technology">
            <div class="col-lg-2"></div>
            <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
              <img alt="RESTful API" title="RESTful API" class="img-responsive center-block" src="/assets/images/restful-logo.png" />
            </div>
            <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
              <img alt="NodeJS" title="NodeJS" class="img-responsive center-block" src="/assets/images/nodejs.png" />
            </div>
            <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
              <img alt="Angular 4" title="Angular 4" class="img-responsive center-block" src="/assets/images/angular-logo.png" />
            </div>
            <div class="col-lg-2 col-sm-3 col-xs-6 text-center">
              <img alt="Javascript" title="Javascript" class="img-responsive center-block" src="/assets/images/js-logo.png" />
            </div>
          </div>
      </div>
    </div>

  </div><!-- END case-wrapper mapp-ver2 -->
</div>
