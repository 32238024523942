import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators'


import { AppSettings } from '../appSettings';

import { Contact } from './contact';

@Injectable()
export class ContactService {
    constructor(private http: HttpClient) { }
    sendEmail(model: Contact): Observable<string> {

        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = { headers: headers };

        return this.http.post<SendLeadEmailResponse>(AppSettings.API_ENDPOINT + "SendEmail/SendLeadEmail",
                                      JSON.stringify(model), options)
            .pipe(
              map(response => response.Response)
            );

        //.catch(error => {
        //    console.log(error);
        //    return Observable.throw(error);
        //});
    }

    // Error handling
    errorHandl(error) {
       let errorMessage = '';
       if(error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
       } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       }
       console.log(errorMessage);
       return throwError(errorMessage);
    }
}

class SendLeadEmailResponse {
  Response: string;
}
