<div class="hero-image-seo">
  <div class="hero-text">
      <h1>SEARCH ENGINE OPTIMIZATION</h1>
      <p>Expand your audience with proper SEO implementation</p>
  </div>
</div>
<div class="container seo-wrapper">
  <div class="row">
      <div class="col-sm-4">
          <img class="img-fluid" alt="Organic Search Engine Optimization" title="Organic Search Engine Optimization" src="/assets/images/organic-seo.jpg" />
          <h3><strong>Organic Optimization</strong></h3>
          <p>Organic SEO should be the first step in the process of SEO implementation. Utilizing keywords that is geared towards your industry to obtain a natural placement on organic search engine results pages (SERPs).</p>
          <br class="visible-xs" />
      </div>
      <div class="col-sm-4">
          <img class="img-fluid" alt="Onsite SEO" title="Onsite SEO" src="/assets/images/onsite-seo.jpg" />
          <h3><strong>Onsite SEO</strong></h3>
          <p>Make your website search engine friendly by conducting an onsite SEO audit for weak spots, and adjusting certain elements within your website so that crawlers are able to understand the content and structure of your website.</p>
          <br class="visible-xs" />
      </div>
      <div class="col-sm-4">
          <img class="img-fluid" alt="Offsite SEO" title="Offsite SEO" src="/assets/images/offsite-seo.jpg" />
          <h3><strong>Offsite SEO</strong></h3>
          <p>Move up the ranks within search engine results pages (SERPSs), gain more traffic, and visibility by applying Offsite SEO, also known as “off-page SEO”. This feature also improves your site’s popularity, relevance, trustworthiness, and authority.</p>
      </div>
  </div>
</div>
