import { Component, OnInit, Directive} from '@angular/core';
// service
import { SocialMediaFeedService } from './socialmediafeed.service';
// model
import { SocialPostModel } from './socialpost.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  socialPosts: SocialPostModel[];

  constructor(private socialMediaFeedService: SocialMediaFeedService) { }

  async ngOnInit() {
    this.socialPosts = await this.socialMediaFeedService.getSocialPosts();

  }

}
