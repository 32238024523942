import { Component, OnInit, Directive} from '@angular/core';

@Component({
  selector: 'app-tech2connect',
  templateUrl: './tech2connect.component.html',
  styleUrls: ['./tech2connect.component.css']
})
export class Tech2connectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
