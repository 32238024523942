<!-- parallax -->
<div class="container-fluid quote-parallax">
  <div class="row">
      <div class="container">
              <h1 class="text-center">REQUEST A QUOTE</h1>
              <h3 class="text-center">Let us help you build your next dream!</h3><br />
              <p class="text-center">* Indicates required field</p>
              <br />
              <div class="col-md-6 col-sm-7 col-centered quote-form">
                  <form #quoteForm="ngForm">
                      <div class="form-group">
                          <input type="text" class="form-control input-lg" id="name" placeholder="Full name *" [(ngModel)]="quoteViewModel.FullName" name="FullName" #fullName="ngModel" required>
                          <div *ngIf="fullName.errors && (fullName.dirty || fullName.touched)">
                              <div [hidden]="!fullName.errors['required']">
                                  <span class="error-message">Please enter your name</span>
                              </div>
                          </div>
                      </div>
                      <div class="form-group">
                          <input type="text" class="form-control input-lg" id="companyName" placeholder="Company name (optional)" [(ngModel)]="quoteViewModel.CompanyName" name="CompanyName" #companyName="ngModel">
                      </div>
                      <div class="form-group">
                          <input type="text" class="form-control input-lg" id="email" placeholder="Email address *" [(ngModel)]="quoteViewModel.Email" name="Email" #email="ngModel" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$" required>
                          <div *ngIf="email.errors && (email.dirty || email.touched)">
                              <div [hidden]="!email.errors['pattern'] && !email.errors['required']">
                                  <span class="error-message">Please enter a valid email address</span>
                              </div>
                          </div>
                      </div>
                      <div class="form-group">
                          <input type="text" class="form-control input-lg" id="phone" placeholder="Contact number *" [(ngModel)]="quoteViewModel.Phone" name="Phone" #phone="ngModel" required>
                          <div *ngIf="phone.errors && (phone.dirty || phone.touched)">
                              <div [hidden]="!phone.errors['required']">
                                  <span class="error-message">Please enter a contact number</span>
                              </div>
                          </div>
                      </div>
                      <div class="form-group">
                          <label>What is the primary reason for this quote? *</label>
                          <select multiple class="form-control" [(ngModel)]="quoteViewModel.Reason" name="Reason" #reason="ngModel" required>
                              <option>Web Development</option>
                              <option>Website Redesign</option>
                              <option>Single Page Applications</option>
                              <option>E-commerce</option>
                              <option>Other</option>
                          </select>
                          <div *ngIf="reason.errors && (reason.dirty || reason.touched)">
                              <div [hidden]="!reason.errors['required']">
                              </div>
                          </div>
                      </div>
                      <div class="form-group">
                          <label>Project deadline</label>
                          <input type="text" class="form-control input-lg" id="deadLine" placeholder="Enter a date" [(ngModel)]="quoteViewModel.Deadline" name="Deadline" #deadline="ngModel">
                      </div>
                      <div class="form-group">
                          <label>Please provide a detailed desciption of your next project *</label>
                          <textarea class="form-control" rows="5" id="message" placeholder="Enter project description *" [(ngModel)]="quoteViewModel.Description" name="Description" #projectDescription="ngModel" minlength="10" required></textarea>
                          <div *ngIf="projectDescription.errors && (projectDescription.dirty || projectDescription.touched)">
                              <div [hidden]="!projectDescription.errors['required'] && !projectDescription.errors['minlength']">
                                  <span class="error-message">Please enter at least 10 characters</span>
                              </div>
                          </div>
                      </div>
                      <button type="submit" class="btn btn-lg" [disabled]="!quoteForm.form.valid" (click)="sendQuote()">SUBMIT MESSAGE</button>
                  </form>
              </div>
      </div>
  </div>
</div>