<div class="container-fluid graphic-design">
  <div class="container">
      <div class="row">
          <div class="col-sm-6 pt-5">
              <h1>GRAPHIC <strong>DESIGN</strong></h1>
              <p>Graphic designers have a tough job. They are critiqued subjectively, and even when one client is happy with their work, the next client might not be. We have two veteran graphic designers, each with a different style and expressive personality, and we recommend the one we think will best bring your vision to life.</p>
          </div>
          <div class="col-sm-6">
            <img class="img-fluid" src="/assets/images/nino-newsletter.jpg" />
          </div>
      </div>
  </div>
</div>
<div class="hero-image-uiux">
  <div class="hero-text">
      <h1>UI/UX GRAPHICS</h1>
      <p>Simple, Clean, Sleek, Minimal..</p>
  </div>
</div>
<div class="container-fluid ui">
  <div class="container">
      <div class="row">
          <div class="col-sm-6">
              <h1>USER <strong>INTERFACE</strong></h1>
              <p>Designing any user interface is easy, but designing a good user interface can mean exponentially more work. Mediocre UIs happen because developers rush to tie the controls directly to the underlying database without taking into consideration of how the human user will interact with the application. A good user interface decouples the UI from the database, and that requires writing code to abstract complicated data into a user friendly front end.</p>
          </div>
      </div>
  </div>
</div>
<div class="container-fluid ux">
  <div class="container">
      <div class="row">
          <div class="col-sm-6">
              <h1>USER <strong>EXPERIENCE</strong></h1>
              <p>A good user experience translates directly to higher sales for an ecommerce website, and better product usage for web applications. Building a website with good user experience is time consuming. It involves research to determine where users are getting stuck or disengaged in the website. Resolving poor user experience usually requires changing the backend business logic to flow with a new, more intuitive workflow.</p>
          </div>
      </div>
  </div>
</div>
