import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';



import { AppSettings } from '../appSettings';

// model
import { BlogArticles } from './blog.model';

@Injectable()
export class BlogService {

  constructor(private http: HttpClient) { }

      getBlogArticles(): Observable<BlogArticles[]> {
          // Parameters obj-
          let params: URLSearchParams = new URLSearchParams();

          let headers = new HttpHeaders({
              'Accept': 'application/json'
          });

          let options = {
              headers: headers,
              search: params
          };

          return this.http.get<BlogArticles[]>('/GetRecentArticles', options);
      }

      // get subscriptions options
    //  getBlogArticlesMock(): Observable<BlogArticles[]> {

         // return Observable.of(SELECTMEMBERPRODUCTS);

    //  }

}
