<!-- footer -->
<div class="container-fluid instagram-feed-bg">
    <div class="row">
      <div
        *ngFor="let post of socialPosts; index as i"
        class="p-0 col-6 col-sm-4 col-lg-2"
        [ngClass]="{'d-none' : i > 1,
                    'd-sm-block' : i == 2,
                    'd-lg-block' : i > 2}"
      >
        <img class="img-fluid" src="{{post.ImageUrl}}" />
      </div>
    </div>
</div>
<div class="container-fluid footer">
  <div class="container">
      <div class="row">
          <div class="col-12 col-md-2">
              <h4>Services</h4>
              <a routerLink="/web-development">Web Development</a>
              <br />
              <a routerLink="/single-page-application">SPA Application</a>
              <br />
              <a routerLink="/angular">Should I use Angular 8?</a>
              <br />
              <a routerLink="/e-commerce">E-commerce</a>
              <br />
              <a routerLink="/ui-ux">UI/UX</a>
              <br />
              <a routerLink="/seo">SEO</a>
          </div>
          <div class="col-12 col-md-2">
              <h4>Our Work</h4>
              <a routerLink="/portfolio">Portfolio</a>
              <br />
              <a routerLink="/case-studies">Case Studies</a>
              <br />
              <a routerLink="/blog/sdlh-tech-public-health">Blog</a>
              <h4>Company</h4>
              <a routerLink="/about-us">About Us</a>
              <br />
              <a routerLink="/how-we-build-value">How We Build Value</a>
              <br />
              <a routerLink="/why-choose-us">Why Choose Us?</a>
              <br />
              <a routerLink="/careers">Careers</a>
          </div>
          <div class="col-12 col-md-2">
              <h4>Contact Us</h4>
              <a routerLink="/contact">Contact Us</a>
              <br />
              <a routerLink="/quote">Request a Quote</a>
              <h4>Office</h4>
              3180 University Ave #215<br />
              San Diego, CA 92104<br />
              888.882.ECOM (3266)<br />
              <a href="mailto:sales@sarsis.com">sales&#64;sarsis.com</a>
          </div>
          <div class="col-12 col-md-3">
              <h4>Stay Connected</h4>
              <a href="https://www.instagram.com/sarsiswebdevelopers/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
              <a href="https://www.yelp.com/biz/sarsis-san-diego" target="_blank"><i class="fa fa-yelp" aria-hidden="true"></i></a>
              <a href="https://www.facebook.com/sarsiswebdevelopers" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
          </div>
          <div class="col-12 col-md-3">
              <br class="visible-xs" />
              <img class="img-fluid footer-logo center-block" alt="Sarsis Web Development Company" title="Sarsis Web Development Company" src="./assets/images/sarsis-footer-logo.png" />
          </div>
      </div>
  </div>
</div>

<div class="container-fluid copyright">
  <div class="col-sm-12 text-center">
      <p>Copyright &copy; 2024 | Sarsis | Health &amp; Technology Solutions</p>
  </div>
</div>
