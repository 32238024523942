import { Component, OnInit, Directive} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-sdlh-tech-public-health',
  templateUrl: './sdlh-tech-public-health.component.html',
  styleUrls: ['./sdlh-tech-public-health.component.css']
})
export class SdlhTechPublicHealthComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("How Technology Supported the Success of the SD Latino Health Campaign to increase Vaccination Rates");

    this.metaTagService.addTags([
      { name: 'keywords', content: 'Public Health, San Diego Latino Health, Technology' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Jaina Pallasigui, Sarsis' },
      { property: 'og:image', content: 'https://www.sarsis.com/assets/images/blog/sdlh_tech_social_post.jpg' },
    ]);

  }

}
