<!-- hero image parallax and content -->
<!-- parallax -->
<div class="container-fluid p-0">
    <div class="contact-hero-parallax">
        <div class="contact-caption">
            <h1>PRIVACY</h1>
        </div>
    </div>
</div>

<!-- row 1 -->
<div class="container contact">
  <div class="row">
    <div class="col-12 p-5">
        Privacy and policy:
        <br/>
        <br/>
        No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.
        <br/>
        <br/>
        While SARSIS makes a best effort to secure consumer data, by filling in and submitting personal information, consumer holds SARSIS harmless from 
        any data breach / data loss. Cyber threats are always evolving, and therefore, it is impossible to know and prevent every single type of cyber attack. 
        Users of this website, and client of SARSIS understands that there will always be a risk of data compromise, data loss, and negative business impact resulting 
        from sending and receiving personal data online.
        <br/>
        <br/>
        Clients, consumers, and anyone sending information to Sarsis shall not hold SARSIS liable, even partially, for any type of loss resulting from transacting with SARSIS.
        <br/>
        <br/>
        If you cannot afford to risk your data being compromised, do not send it online!
    </div>
  </div>
</div>