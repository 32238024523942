<div class="container-fluid p-0">
  <div class="bg-hero">
    <div class="p-2">
      <div class="hero-text section-indent">
        <div class="sub-header">
          August 2022: National Immunization Awareness Month
        </div>
        <h1>How Technology Supported the Success of the SD Latino Health Campaign to increase Vaccination Rates</h1>
      </div>

    </div>

  </div>


  <div class="container pt-5">
    <p>
    Public health campaigns that use technology effectively can significantly broaden reach to target audiences. This is because information technology is considered essential in order to promote communication strategies that “improve population health outcomes and health care quality, and to achieve health equity” (Healthy People 2030). Technology is even more important with the majority of people seeking information and news online. To support the San Diego Latino Health Campaign (SDLH), the lead coalition partner, Chicano Federation, hired Sarsis Health and Technology to build a website that leveraged technology to greatly expand reach to under-resourced communities.
    </p>
    <p>
        When the COVID-19 vaccine was introduced, the Latino vaccination rate was lagging in San Diego County. Many Latinos did not trust the government or medical information, which resulted in low vaccination rates. It was evident that there was a great need to increase awareness and education about the vaccine in this community. In response, SVPR Communications created the SD Latino Health Campaign to promote masking, covid testing, and when the vaccine became available, increase vaccination rates among Latinos.
    </p>

    <p>
    Using collateral provided by SVPR Communications, such as graphics and video, Sarsis designed, built, and launched the SDLH website to reach the campaign’s target Latino population. Sarsis’ experience in previous projects that serve similar demographics allowed the company to quickly design a website with the intended audience in mind. The website was created in Spanish, with a simple design. SVPR Communications collateral was transformed into web and mobile-friendly formats, with rotating content to keep the community engaged. Sarsis’ graphic designer regularly works with monolingual and/or bilingual Latino communities, so this expedited the process of converting printed materials into digital content. The print collateral was converted to HTML for search engine optimization, allowing for more people to easily find the website. Sarsis’ diverse staff also includes Latino members, who were available to focus test the Spanish website and ensure it was user-friendly.
    </p>

    <h3>Using a Technological Infrastructure for Scaling</h3>
    <p>
      Sarsis created an infrastructure to scale the website dynamically and adapt to surges of traffic brought to the site by SVPR Communications, LLC. One of the challenges for the website was adapting to irregular traffic patterns. To overcome this hurdle, Microsoft Cloud Services was used to adapt the server capacity to scale up or down, depending on traffic loads from the campaign. Microsoft’s Azure Autoscale was implemented to leverage alerts from campaign events and newscasts, which allowed Sarsis to prepare for the influx of visitors to the website in advance. This enabled the server power to keep page load times under 3 seconds, even when thousands of people were visiting the site a day. Furthermore, the adaptive nature of the website’s server capacity allowed the resources to be downsized after campaign events, when website traffic receded, saving significant money for the campaign.
    </p>

    <p>
      The SDLHC website also reformatted and optimized collateral to be delivered on mobile devices. This was an important component to the reach of the campaign since 75% of traffic came from mobile phones and tablets. Therefore, a scaled down version of the site was delivered to ensure the community still received critical updates through the familiar means by which they consume information.
    </p>

    <p>
      To evaluate the engagement with the website, javascript code was added to track which resources were being downloaded the most. This data was then reported back to the coalition to gauge effectiveness of messages in near real-time. Using this data, Sarsis moved content that engaged with the visitors at the top of the page, while rotating out less popular ads and banners.
    </p>

    <p>
      When Sarsis partnered with SD Latino Health Coalition, the campaign was elevated by broadening reach to the target population and resulted in increased vaccine rates among Latinos in San Diego County. Public health campaigns provide the front-facing strategy to meet objectives, while technology supports these initiatives. Creating a tailored website based on SVPR Communications collateral, and traffic SVPR Communications brought to the site is a prime example of how technology can support and drive public health campaigns behind the scenes.
    </p>

    <p>
      Sources:
      <a href="https://www.healthypeople.gov/2020/topics-objectives/topic/health-communication-and-health-information-technology">https://www.healthypeople.gov/2020/topics-objectives/topic/health-communication-and-health-information-technology</a>
    </p>
  </div>
</div>
