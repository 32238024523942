import { Component, OnInit, inject, TemplateRef } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { CommonModule } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
  imports: [ CommonModule ],
  standalone: true
})
export class AboutUsComponent implements OnInit {
  private modalService = inject(NgbModal);
  profileDescription: string;
  profileName: string;
  constructor(meta: Meta, title: Title) {

        title.setTitle('Sarsis | About Us, Get to know the team at Sarsis');

            meta.addTags([
              { name: 'author',   content: 'sarsis.com'},
              { name: 'keywords', content: 'Enterprise Web Development, Web Development, E-commerce, Angular 2/4 Service, Single Page Application Development, C#, .NET, Javascript, HTML, CSS, Bootstrap 3/4'},
              { name: 'description', content: 'Enterprise Web Application Development Company located in San Diego' }
            ]);
       }

    ngOnInit() {
      
    }

    openModal(content: TemplateRef<any>, name: string) {
      this.profileName = name;
      this.modalService.open(content);
    }

}
