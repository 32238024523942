<!-- parallax -->
<div class="container-fluid p-0">
    <div class="spa-hero-parallax">
        <div class="spa-caption">
            <h1>SPA APPLICATIONS</h1>
            <br />
            <button type="button" routerLink="/angular" class="btn btn-primary btn-lg">Should I Use Angular?</button>
        </div>
    </div>
</div>

<!-- row 1 -->
<div class="container">
  <div class="row spa">
      <div class="col-md-8">
          <h1><strong>Angular:</strong> The Future of Web Design</h1>
          <br />
          <p>Websites coded with Angular give the fastest load times we’ve seen, and your clients will love the blazing fast responsiveness. We keep up to date with the most cutting edge technologies that deliver the best performance available.</p>
          <p>With an Angular framework, your website runs as a single page application, or “SPA” for short. This means when your site first loads, then the entire website is downloaded to the client’s browser. When the client clicks or taps on another page, that page is retrieved from the browser cache, instead of making a round trip to the server. This allows for insanely fast page response times not possible with traditional server side web pages.</p>
      </div>
      <div class="col-md-4">
          <h1>Angular Material Design</h1>
          <br />
          <p>When appropriate, we use the Angular Material Design library for our clients’ projects. The design library contains a collection of modern forms and controls that make even mundane back office applications easy to build, modern, and sleek.</p>
      </div>
  </div>
</div>
