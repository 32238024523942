import { Component, Directive, afterNextRender} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

// Declare ga function as ambient
//declare var ga: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {

  constructor(private router: Router) {

    // Google analytics page tracking
    /**
    router.events.distinctUntilChanged((previous: any, current: any) => {
        if (current instanceof NavigationEnd) {
            return previous.url === current.url;
           }
          return true;
    }).subscribe((x: any) => {
           console.log('router.change', x);
          ga('send', 'pageview', x.url);
        });
    */
        afterNextRender(() => {
          this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            //window.scrollTo(0, 0)
            //console.log('scrollTo');
        });
        });

    }

    // scroll to top
    ngOnInit() {

    }

}